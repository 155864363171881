.scroll1 {
    position: relative;
    display: flex;
    overflow: hidden;
    align-self: stretch;
    border-radius: 5px;
}

/* Scrolling container settings */
.scroll-container {
    display: flex;
    white-space: nowrap;
}

/* Top Scroll - Left Direction */
.top-scroll .scroll-container {
    animation: scroll-left var(--time) linear infinite;
}

/* Bottom Scroll - Right Direction */
.bottom-scroll .scroll-container {
    animation: scroll-right var(--time) linear infinite;
}

/* Keyframes for Scrolling Left */
@keyframes scroll-left {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

/* Keyframes for Scrolling Right */
@keyframes scroll-right {
    0% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(0);
    }
}

.scroll-image {
    max-width: 300px;
    margin-inline: 2px;
    min-height: 200px;
    object-fit: cover;
    max-height: 300px;
    filter: brightness(0.7);
    border-radius: 5px;
    transform: scale(1);
}
